<template>
    <div class="contact">
        <head-img desc="联系我们" :imgUrl="contactBgUrl"></head-img>
        <my-menu :titleList="titleList" :menuList="menuList"></my-menu>
        <div class="contact-content">
            <div class="content">
                <div class="text" v-html="information.detailsContent"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            active: 0,
            contactBgUrl: require('@/assets/img/home/lunbo5.jpg'),
            titleList: [
                {name: '联系我们'},
            ],
            menuList: [
                {name: '首页', path: '/'},
                {name: '联系我们'},
            ],
            id: '106a2f0b1d1412fc2af55ccfbd98f671',
            information:{}
        }
    },
    methods: {
        getDetail() {
            this.$store.commit('loading', '.contact .contact-content')
            this.$api.post('bill/article/article-showForHomePage', {id: this.id}).then(res=>{
                console.log(res)
                this.information = res.data
                this.titleList[0].name = res.data.articleName
                this.menuList[1].name = res.data.articleName
            })
            .finally(()=>{
                this.$store.commit('loaded')
            })
        }
    },
    mounted() {
        this.getDetail()
    }
}
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    .contact{
        .contact-content{
            display: flex;
            justify-content: center;
            .content{
                width: 1200px;
                padding: 20px 0;
                .title{
                    display: flex;
                    justify-content: center;
                    font-size: 32px;
                    color: #333;
                    span{
                        display: inline-block;
                        padding-bottom: 10px;
                        border-bottom: 1px solid #ccc;
                    }
                }
                .text{
                    padding: 15px;
                    min-height: 150px;
                }
            }
        }
    }

</style>